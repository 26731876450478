<template>
  <div>
    <div class="wameed-dashboard-page-content mb-5">
      <div>
        <page-header
          :title="$t('orders_page.details_title_order')"
          :sub-title="$t('dashboard.subtitle')"
          :btn="getOrderDetails && getOrderDetails.offers_sent"
          variant="main"
          :btn-title="$t('common.browser_offers')"
          classes="text-bold-15 text-white w-cus-sm-100"
          @btnAction="browserOffers"
        />
      </div>
      <section class="wameed-dashboard-page-content_body">
        <div class="mx-4">
          <b-row class="">
            <b-col md="6" class="my-4">
              <order-details-table
                v-if="getOrderDetails"
                :id="getOrderDetails.id"
                :title="getOrderDetails.hr_job_title"
                :employee-job-title="getOrderDetails.employee_job_title"
                :required-jobs-no="getOrderDetails.required_jobs_no"
                :date="getOrderDetails.created_at"
                :description="getOrderDetails.description"
                :industry-preference="getOrderDetails.industry_preference.name"
                :file="getOrderDetails.file"
                :role-preference="getOrderDetails.role_preference"
                :services="getOrderDetails.services"
                :status="getOrderDetails.status"
                :offers-sent="getOrderDetails.offers_sent"
                :has-offers="getOrderDetails.has_offers"
              />
            </b-col>
            <b-col
              v-if="getOrderDetails && getOrderDetails.status == 3"
              md="6"
              class="mt-md-4 mb-4 mt-0"
            >
              <div
                class="
                  border-main
                  rounded-14
                  bg-light-main
                  d-flex
                  p-3
                  flex-wrap
                "
              >
                <div class="col-12 d-flex align-items-center p-0">
                  <div class="pr-4 d-flex align-items-center">
                    <check-payment-icon />
                  </div>
                  <div class="d-flex flex-column">
                    <span class="text-font-main text-bold-15">
                      {{ $t('common.alert_payment') }}
                    </span>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OrderDetailsTable from '@/components/pages/orders/OrderDetailsTable.vue';
import PageHeader from '@/components/PageHeader.vue';
import OrdersCard from '@/components/pages/orders/OrdersCard.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import WameedPagination from '@/components/WameedPagination.vue';
import offersCard from '@/components/pages/orders/OffersCard.vue';

export default {
  components: {
    OrderDetailsTable,
    PageHeader,
    OrdersCard,
    WameedBtn,
    WameedNoData,
    FilterHeader,
    WameedPagination,
    offersCard,
  },
  data() {
    return {
      applyOffer: false,
    };
  },
  computed: {
    ...mapGetters({
      getTotalOrders: 'getTotalOrders',
      getOrderDetails: 'getOrderDetails',
    }),
  },
  watch: {},
  mounted() {
    this.loadOrdersDetails(this.$router.currentRoute.params.id);
  },

  methods: {
    ...mapActions({
      loadOrdersDetails: 'loadOrdersDetails',
      loadFile: 'loadFile',
    }),
     formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), "days") > 7
          ? this.moment(date).format("DD/MM/YYYY")
          : this.moment(date).fromNow(true);
      }
        return '------';
    },
    browserOffers() {
      this.$router.push({
        name: 'offers-for-order',
        params: {
          lang: this.$i18n.locale,
        },
      });
    },
  },
};
</script>
